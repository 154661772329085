import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public anio: number = new Date().getFullYear(); // Valor por defecto

  constructor() {}

  ngOnInit() {
    this.obtenerAnioChile();
  }

  obtenerAnioChile() {
    const fechaChile = new Intl.DateTimeFormat("es-CL", {
      year: "numeric",
      timeZone: "America/Santiago"
    }).format(new Date());

    this.anio = parseInt(fechaChile, 10);
  }
}
